import React from 'react'
import Title1 from '../components/title1';
import Timeline from '../components/timeline';


function Service() {
    return ( <div className="container mx-auto bg-gris py-10
    ">
        <Title1 text='Service' classes='text-center ' />
        <Timeline />
    </div> );
}

export default Service;