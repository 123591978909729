import React from 'react'

function Cta() {
    return ( <div className="cta ">
        <div className="container mx-auto">
        <h4>NOTRE SAVOIR - FAIRE - VALOIR</h4>
        <h4 className='big'>Au coeur de vos <br /> plus grands rêves</h4>
        <a href="/contact">
        <button className="btn1">Contacter nous maintenant</button>
        </a>
        </div>
        </div> );
}

export default Cta;