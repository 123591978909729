import React from "react";

function Timeline() {
  return (
    <div className="timeline">
      <div className="grid grid-cols-1 md:grid-cols-2 items-center hidden md:grid">
        <div className="left line py-10">
            <div className="text">
                <h6>Agence de voyage</h6>
            </div>
            <div className="icon">
                <img src="/img/travel.svg" alt="icon" />
            </div>
        </div>

        <div className="right py-10"></div>
        <div className="left py-10"></div>
        <div className="right line py-10">
        <div className="icon">
                <img src="/img/service.svg" alt="icon" />
            </div>
            <div className="text">
                <h6>Multiservices</h6>
            </div>
            
        </div>


        <div className="left line py-10">
            <div className="text">
                <h6>Immobilier</h6>
            </div>
            <div className="icon">
                <img src="/img/immo.svg" alt="icon" />
            </div>
        </div>

        <div className="right py-10"></div>
        <div className="left py-10"></div>
        <div className="right line py-10">
        <div className="icon">
                <img src="/img/assurance.svg" alt="icon" />
            </div>
            <div className="text">
                <h6>Assurance</h6>
            </div>
            
        </div>
    
        <div className="left line py-10">
            <div className="text">
                <h6>Bâtiment et Traveaux Public</h6>
            </div>
            <div className="icon">
                <img src="/img/btp.svg" alt="icon" />
            </div>
        </div>

        <div className="right py-10"></div>
        <div className="left py-10"></div>
        <div className="right line py-10">
        <div className="icon">
                <img src="/img/tl.svg" alt="icon" />
            </div>
            <div className="text">
                <h6>Transport Logistique</h6>
            </div>
            
        </div>
            
      </div>


      <div className="grid grid-cols-1 md:grid-cols-2 items-center grid md:hidden">
        <div className="left line py-10">
        <div className="icon">
                <img src="/img/travel.svg" alt="icon" />
            </div>
            <div className="text">
                <h6>Agence de voyage</h6>
            </div>
            
        </div>

        <div className="right line py-10">
        <div className="icon">
                <img src="/img/service.svg" alt="icon" />
            </div>
            <div className="text">
                <h6>Multiservices</h6>
            </div>
            
        </div>


        <div className="left line py-10">
        <div className="icon">
                <img src="/img/immo.svg" alt="icon" />
            </div>
            <div className="text">
                <h6>Immobilier</h6>
            </div>
            
        </div>

        <div className="right line py-10">
        <div className="icon">
                <img src="/img/assurance.svg" alt="icon" />
            </div>
            <div className="text">
                <h6>Assurance</h6>
            </div>
            
        </div>
    
        <div className="left line py-10">
        <div className="icon">
                <img src="/img/btp.svg" alt="icon" />
            </div>
            <div className="text">
                <h6>BTP</h6>
            </div>
            
        </div>

        <div className="right line py-10">
        <div className="icon">
                <img src="/img/tl.svg" alt="icon" />
            </div>
            <div className="text">
                <h6>Transport Logistique</h6>
            </div>
            
        </div>
            
      </div>
    </div>
  );
}

export default Timeline;
