import React from 'react'
import Title1 from '../components/title1';

function Chiffres() {
    return ( 
        <div className="bg-secondary p-4 text-white">
            <Title1 text='Chiffres' classes='text-center' />
            <div className="grid grid-cols-1 md:grid-cols-4 text-center py-10">
                <div className="item justify-center py-10 ">
                    <div className="icon "><img src="/img/costumer.svg" className='inline' alt="icon" /></div>
                    <h4 className='font-bold text-3xl py-4'>+250</h4>
                </div>

                <div className="item justify-center py-10 ">
                    <div className="icon "><img src="/img/airplane.svg" className='inline' alt="icon" /></div>
                    <h4 className='font-bold text-3xl py-4'>+250</h4>
                </div>

                <div className="item justify-center py-10 ">
                    <div className="icon "><img src="/img/airplane.svg" className='inline' alt="icon" /></div>
                    <h4 className='font-bold text-3xl py-4'>+250</h4>
                </div>

                <div className="item justify-center py-10 ">
                    <div className="icon "><img src="/img/costumer.svg" className='inline' alt="icon" /></div>
                    <h4 className='font-bold text-3xl py-4'>+250</h4>
                </div>
            </div>
        </div>
     );
}

export default Chiffres;