import React from 'react'
import Topbar from '../../components/topbar';
import HeroSm from '../../components/heroSm';
import Title1 from '../../components/title1';
import Footer from '../../sections/footer';
import Card1 from '../../components/card1';
import Cta from '../../sections/cta';


function About() {
    React.useEffect(() => {
        document.title = 'LAWEL DIA SERVICES - A propos de nous';
      }, []);
    return ( <div>

        <Topbar />
        <HeroSm title='A propos' img='about-hero.png' />
        <div>
            <Title1 text='Qui sommes nous' classes='text-center' />

            <div class="grid grid-cols-1  lg:grid-cols-3   items-center">
                <div className='w-full'>
                    <img src="/img/about.png" alt="about img" />
                </div>
                <div className='p-4 col-span-2 lg:ms-5 lg:me-20'>
                    <h1 className=' text-[35px] text-secondary'>Notre Histoire</h1>
                    <p className='text-justify text-lg text-gray-800'>
                Fondée en 2014 par Seikou DIA, LAWEL DIA SERVICES (LDS) est née de l’ambition de faciliter les démarches administratives des jeunes Sénégalais et ressortissants de l'Afrique de l'Ouest désireux d'émigrer vers des destinations comme l'Europe, la Chine, les États-Unis et le Canada. Face à des procédures complexes et souvent inaccessibles, LDS s’est donné pour mission de simplifier ces processus et d’aider ses clients à réaliser leurs rêves de voyage.
<br /><br />
Avec le temps, LDS a élargi son champ d’action, offrant aujourd'hui des services variés dans les secteurs de l'immobilier, des assurances, de la location et vente de véhicules, et bien plus encore.
</p>
<h1 className=' text-[35px] text-secondary '>Expansion et Ambitions Futures</h1>
<p className='text-justify text-lg text-gray-800'>
Notre siège social est situé à Dakar, avec trois agences implantées à VDN, Ouakam et Pikine. Dans notre objectif de continuer à servir nos clients à travers tout le Sénégal, nous avons l’ambition d’étendre notre présence à Thiès, Saint-Louis, Ziguinchor et Mbour.  
</p>
                </div>
            </div>
        </div>

        <div className="">

        <div className="container mx-auto my-10">
            <div class="grid grid-cols-1 xl:grid-cols-2 items-center">
        <Card1 title='Notre Mission'
        text="
        La mission de LAWEL DIA SERVICES est de rendre les démarches administratives et autres services essentiels plus accessibles et plus simples pour tous. Nous accompagnons nos clients dans leurs projets de voyages, d’investissements immobiliers ou d'assurances, en leur offrant une assistance personnalisée et de qualité.
        " 
        img='mission.jpg'
        />
        <Card1 title='Nos Réalisations'
        text="
        Depuis sa création, LDS a contribué à l'emploi et à l'épanouissement de plus de 300 jeunes diplômés sans emploi, devenus par la suite des entrepreneurs prospères. Actuellement, LAWEL DIA SERVICES emploie 100 personnes dans divers secteurs.

Nous sommes particulièrement fiers d’avoir bâti cette entreprise sans contracter de prêts bancaires, grâce à un modèle économique fondé sur le travail acharné et l'indépendance financière.
        " 
        img='realisation.jpg'
        />

<Card1 title='Engagement Social'
        text="
        LAWEL DIA SERVICES s’engage également dans des initiatives sociales pour soutenir le développement communautaire. Nous avons entrepris plusieurs projets, tels que la reconstruction de mosquées, la création de forages pour l’accès à l’eau potable, et le soutien aux jeunes et aux personnes dans le besoin.

En outre, Seikou DIA, notre directeur général, est fier de présider l'Association Sportive et Culturelle (ASC) de Niéti Mbar dans la commune de Djida Thiaroye Pikine. Cette initiative vise à promouvoir le bien-être et la culture dans notre communauté.
        " 
        img='engagement.jpg'
        />

<Card1 title='Remerciements'
        text="
        Nous tenons à remercier chaleureusement tous nos partenaires, nos employés et nos clients pour leur confiance et leur soutien. Ensemble, nous continuerons à bâtir une entreprise prospère et à offrir des services qui répondent aux besoins de notre communauté et au-delà." 
        img='remerciement.jpg'
        />


        </div>

        </div>


        </div>

        <Cta />
        <Footer />
        </div> );
}

export default About;