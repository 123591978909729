'use client'

import { Carousel } from 'flowbite-react'
import { useState } from 'react'



export default function Hero() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <div className="relative w-full h-[50vh] lg:h-[100vh] bg-primary">
    <Carousel slide={true}>

      {/* Slide 1 */}
      <div className="relative h-full overflow-hidden">
        <img src="/img/hero1.png" className="absolute block w-full h-full object-cover top-0 left-0" alt="Slide 1" />
        <div className="absolute inset-0 flex flex-col items-start justify-center text-left text-white md:pl-40 pl-20">
          <h2 className="lg:text-6xl md:text-5xl text-3xl font-bold md:leading-[3rem] lg:leading-[5rem] leading-[2rem]">
            Simplifiez vos démarches <br /> administratives
          </h2>
          <p className="mt-2 text-lg hidden lg:block">
            Chez LAWEL DIA SERVICES, nous facilitons vos voyages et projets immobiliers avec une expertise sur-mesure. <br />
            Obtenez des conseils personnalisés pour réussir votre aventure à l’étranger.
          </p>
        </div>
      </div>

      {/* Slide 2 */}
      <div className="relative h-full overflow-hidden">
        <img src="/img/hero2.jpg" className="absolute block w-full h-full object-cover top-0 left-0" alt="Slide 2" />
        <div className="absolute inset-0 flex flex-col items-start justify-center text-left text-white md:pl-40 pl-20">
          <h2 className="lg:text-6xl md:text-5xl text-3xl font-bold md:leading-[3rem] lg:leading-[5rem] leading-[2rem]">
            Des solutions d'assurance <br /> adaptées à vos besoins
          </h2>
          <p className="mt-2 text-lg hidden lg:block">
            Assurez votre tranquillité d'esprit avec nos offres d'assurance complètes et flexibles. <br />
            Découvrez comment nous protégeons ce qui compte le plus pour vous.
          </p>
        </div>
      </div>

      {/* Slide 3 */}
      <div className="relative h-full overflow-hidden">
        <img src="/img/hero3.jpg" className="absolute block w-full h-full object-cover top-0 left-0" alt="Slide 3" />
        <div className="absolute inset-0 flex flex-col items-start justify-center text-left text-white md:pl-40 pl-20">
          <h2 className="lg:text-6xl md:text-5xl text-3xl font-bold md:leading-[3rem] lg:leading-[5rem] leading-[2rem]">
            Location et vente de <br /> véhicules de qualité
          </h2>
          <p className="mt-2 text-lg hidden lg:block">
            Explorez notre vaste gamme de véhicules à louer ou à vendre, <br />
            que ce soit pour vos déplacements professionnels ou personnels. <br />
            Conduisez en toute sérénité avec LAWEL DIA SERVICES.
          </p>
        </div>
      </div>
      
    </Carousel>
  </div>

  )
}



// {/* <div className="relative w-full h-[50vh] lg:h-[100vh] bg-primary">
// <Carousel slide={true}>

//   {/* Slide 1 */}
//   <div className="relative h-full overflow-hidden">
//     <img src="/img/hero1.png" className="absolute block w-full h-full object-cover top-0 left-0" alt="Slide 1" />
//     <div className="absolute inset-0 flex flex-col items-start justify-center text-left text-white md:pl-40 pl-20">
//       <h2 className="lg:text-6xl md:text-5xl text-3xl font-bold md:leading-[3rem] lg:leading-[5rem] leading-[2rem]">
//         Simplifiez vos démarches <br /> administratives
//       </h2>
//       <p className="mt-2 text-lg hidden lg:block">
//         Chez LAWEL DIA SERVICES, nous facilitons vos voyages et projets immobiliers avec une expertise sur-mesure. <br />
//         Obtenez des conseils personnalisés pour réussir votre aventure à l’étranger.
//       </p>
//     </div>
//   </div>

//   {/* Slide 2 */}
//   <div className="relative h-full overflow-hidden">
//     <img src="/img/hero2.jpg" className="absolute block w-full h-full object-cover top-0 left-0" alt="Slide 2" />
//     <div className="absolute inset-0 flex flex-col items-start justify-center text-left text-white md:pl-40 pl-20">
//       <h2 className="lg:text-6xl md:text-5xl text-3xl font-bold md:leading-[3rem] lg:leading-[5rem] leading-[2rem]">
//         Des solutions d'assurance <br /> adaptées à vos besoins
//       </h2>
//       <p className="mt-2 text-lg hidden lg:block">
//         Assurez votre tranquillité d'esprit avec nos offres d'assurance complètes et flexibles. <br />
//         Découvrez comment nous protégeons ce qui compte le plus pour vous.
//       </p>
//     </div>
//   </div>

//   {/* Slide 3 */}
//   <div className="relative h-full overflow-hidden">
//     <img src="/img/hero3.jpg" className="absolute block w-full h-full object-cover top-0 left-0" alt="Slide 3" />
//     <div className="absolute inset-0 flex flex-col items-start justify-center text-left text-white md:pl-40 pl-20">
//       <h2 className="lg:text-6xl md:text-5xl text-3xl font-bold md:leading-[3rem] lg:leading-[5rem] leading-[2rem]">
//         Location et vente de <br /> véhicules de qualité
//       </h2>
//       <p className="mt-2 text-lg hidden lg:block">
//         Explorez notre vaste gamme de véhicules à louer ou à vendre, <br />
//         que ce soit pour vos déplacements professionnels ou personnels. <br />
//         Conduisez en toute sérénité avec LAWEL DIA SERVICES.
//       </p>
//     </div>
//   </div>
  
// </Carousel>
// </div> */}