import React from 'react'

function Footer() {
    return ( <div className="footer">
        <div className="logo"><img src="/img/logo2.png" alt="logo 2" /></div>
        <div className="rs">
            <img src="/img/facebook.svg" alt="icon" />
            <img src="/img/linkedin.svg" alt="icon" />
            <img src="/img/twitter.svg" alt="icon" />
            <img src="/img/insta.svg" alt="icon" />
        </div>
        <div className="menu">
            <ul>
                <li><a href="/">Accueil</a></li>
                <li><a href="/a-propos">A propos</a></li>
                <li><a href="/services">Services</a></li>
                <li><a href="/contact">Contact</a></li>
            </ul>
        </div>

        <div className="copy">
            <p>
            &copy; 2024 LAWEL DIA SERVICE. by <span>AMD</span>. Tous les droits réservés.
            </p>
        </div>
    </div> );
}

export default Footer;