import React from 'react'


function Card1({title="", text="", img ="hero1.png"}) {
    return ( 

<div class="flex flex-col  items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-full xl:max-w-xl  hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700 my-4 ">
    
    <img class="object-cover w-full rounded-t-lg h-96 md:h- md:w-48 md:rounded-none md:rounded-s-lg" src={`/img/${img}`} alt="" />
    <div class="flex flex-col justify-between p-4 leading-normal">
        <h5 class="mb-2 text-2xl font-bold tracking-tight text-secondary dark:text-white">{title}</h5>
        <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">{text}.</p>
    </div>
</div>
     );
}

export default Card1;