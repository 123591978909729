import React from 'react'
import Hero from '../../components/hero';
import Topbar from '../../components/topbar';
import About from '../../sections/about';
import Dg from '../../sections/dg';
import Service from '../../sections/service';
import Chiffres from '../../sections/chiffres';
import Cta from '../../sections/cta';
import Footer from '../../sections/footer';
function Index() {
    React.useEffect(() => {
        document.title = 'LAWEL DIA SERVICES - Bienvenue';
      }, []);
    return ( 
        <div>
        <Topbar />
        <Hero />
        <About />
        <Dg />
        <Service />
        <Chiffres />
        <Cta />
        <Footer />
        </div>
     );
}

export default Index;