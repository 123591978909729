import React from 'react'
import Title1 from '../components/title1';


function About() {
    return ( 
        <div>
            <Title1 text='Qui sommes nous' classes='text-center' />

            <div class="grid grid-cols-1 lg:grid-cols-3   items-center">
                <div className='w-full'>
                    <img src="/img/about.png" alt="about img" />
                </div>
                <div className='p-2 col-span-2  '>
                    <div className="grid grid-cols-1 xl:grid-cols-3">
                        <div className='col-span-2 shadow-lg p-4 xl:mx-4 border bg-secondary'>
                            <p className='text-justify text-md text-gray-200'>
                            LAWEL DIA SERVICES (LDS) est une entreprise dynamique fondée en 2014 par Seikou DIA, dédiée à simplifier les démarches administratives et à offrir des solutions complètes pour vos besoins personnels et professionnels. Nous avons vu le jour pour répondre aux défis complexes auxquels sont confrontés ceux qui souhaitent voyager ou investir, en fournissant un accompagnement expert dans des domaines tels que l’immobilier, les assurances, la location de véhicules et plus encore. Avec notre siège social à Dakar et plusieurs agences dans les quartiers de VDN, Ouakam et Pikine, nous nous engageons à rendre nos services accessibles et efficaces pour tous nos clients.

                            </p>
                        </div>
                        <div></div>
                        <div></div>
                        <div className='col-span-2 shadow-lg p-4 mt-9 lg:mx-4 border bg-primary'>
                            <p className='text-justify text-md text-gray-100'>
Notre mission est de fournir des services de haute qualité avec un engagement profond envers l'excellence et la satisfaction de nos clients. Nous avons élargi notre offre au fil des années pour répondre aux besoins croissants de notre clientèle, tout en restant fidèles à notre vision initiale de simplification des démarches administratives. En plus de notre expertise professionnelle, nous nous impliquons activement dans des initiatives sociales et communautaires, contribuant ainsi au bien-être de la société. Chez LAWEL DIA SERVICES, nous sommes fiers de notre indépendance et de notre capacité à offrir des solutions adaptées, en mettant toujours l’accent sur l’innovation et le service client.

                            </p>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
     );
}

export default About;