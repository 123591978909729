import React from 'react'
import Topbar from '../../components/topbar';
import HeroSm from '../../components/heroSm';
import Footer from '../../sections/footer';
import Cta from '../../sections/cta';


function Service() {
    React.useEffect(() => {
        document.title = 'LAWEL DIA SERVICES - Nos services';
      }, []);
    return ( 
        <div className="services">
            <Topbar />
            <HeroSm title='Nos Services' img='service-hero.jpg' />
            {/* <div class="relative my-12 flex w-full flex-col items-center sm:mt-24">
  <a target="_blank" rel="noreferrer" href="https://example.com"
    class="mx-auto flex max-w-fit items-center justify-center space-x-2 overflow-hidden rounded-full bg-blue-100 px-7 py-2 transition-all hover:bg-blue-200">
    
    <p class="text-sm font-semibold text-[#1d9bf0]">LWS </p>
  </a>
  <h1
    class="mt-8 max-w-sm bg-gradient-to-br from-primary via-secondary to-primary bg-clip-text text-center text-4xl font-extrabold text-transparent sm:max-w-4xl sm:text-6xl">
    LAWEL DIA SERVICE SUARL
    </h1>
  <span class="mt-8 max-w-lg text-center text-xl leading-relaxed text-gray-800">
  Chez LAWEL DIA SERVICES (LDS), nous offrons une large gamme de services pour répondre aux besoins diversifiés de nos clients, qu'il s'agisse de faciliter les démarches administratives ou d'accompagner dans des projets spécifiques. Notre mission est de simplifier votre quotidien en vous proposant des solutions fiables et adaptées, tout en garantissant un accompagnement personnalisé.

  </span>
  
</div> */}

{/* <div className="container mx-auto my-10">
<div class="grid mb-8 border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 md:mb-12 md:grid-cols-2 bg-white dark:bg-gray-800">
    <figure class="flex flex-col items-center justify-center p-8 text-center bg-white border-b border-gray-200 rounded-t-lg md:rounded-t-none md:rounded-ss-lg md:border-e dark:bg-gray-800 dark:border-gray-700">
        <blockquote class="max-w-2xl mx-auto mb-4 text-gray-500 lg:mb-8 dark:text-gray-400">
            <h3 class="text-xl   font-semibold text-gray-900 dark:text-white">Services d'accompagnement aux démarches de voyage</h3>
            <p class="my-4">Vous souhaitez voyager à l'international ? Que ce soit pour des études, du travail ou des loisirs, les démarches administratives peuvent être complexes et décourageantes. LAWEL DIA SERVICES vous accompagne dans tout le processus pour simplifier vos démarches de visa et de migration vers des destinations telles que l'Europe, la Chine, les États-Unis et le Canada.

</p>
        </blockquote>
        
<h2 class="mb-2 text-xl   font-semibold text-gray-900 dark:text-white">Ce que nous offrons :</h2>
<ul class="max-w-md space-y-1 text-gray-500 list-inside dark:text-gray-400">
    <li class="flex items-center">
        <svg class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
         </svg>
         Assistance pour les demandes de visa
    </li>
    <li class="flex items-center">
        <svg class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
         </svg>
         Conseils en immigration
    </li>
    <li class="flex items-center">
        <svg class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
         </svg>
         Préparation des documents de voyage
         </li>
    <li class="flex items-center">
        <svg class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
         </svg>
         Suivi des dossiers jusqu'à obtention des autorisations.
         </li>
</ul>
    
    </figure>

    <figure class="flex flex-col items-center justify-center p-8 text-center bg-white border-b border-gray-200 rounded-t-lg md:rounded-t-none md:rounded-ss-lg md:border-e dark:bg-gray-800 dark:border-gray-700">
        <blockquote class="max-w-2xl mx-auto mb-4 text-gray-500 lg:mb-8 dark:text-gray-400">
            <h3 class="text-xl   font-semibold text-gray-900 dark:text-white">Services d'accompagnement aux démarches de voyage</h3>
            <p class="my-4">Vous souhaitez voyager à l'international ? Que ce soit pour des études, du travail ou des loisirs, les démarches administratives peuvent être complexes et décourageantes. LAWEL DIA SERVICES vous accompagne dans tout le processus pour simplifier vos démarches de visa et de migration vers des destinations telles que l'Europe, la Chine, les États-Unis et le Canada.

</p>
        </blockquote>
        
<h2 class="mb-2 text-xl   font-semibold text-gray-900 dark:text-white">Ce que nous offrons :</h2>
<ul class="max-w-md space-y-1 text-gray-500 list-inside dark:text-gray-400">
    <li class="flex items-center">
        <svg class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
         </svg>
         Assistance pour les demandes de visa
    </li>
    <li class="flex items-center">
        <svg class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
         </svg>
         Conseils en immigration
    </li>
    <li class="flex items-center">
        <svg class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
         </svg>
         Préparation des documents de voyage
         </li>
    <li class="flex items-center">
        <svg class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
         </svg>
         Suivi des dossiers jusqu'à obtention des autorisations.
         </li>
</ul>
    
    </figure>

    <figure class="flex flex-col items-center justify-center p-8 text-center bg-white border-b border-gray-200 rounded-t-lg md:rounded-t-none md:rounded-ss-lg md:border-e dark:bg-gray-800 dark:border-gray-700">
        <blockquote class="max-w-2xl mx-auto mb-4 text-gray-500 lg:mb-8 dark:text-gray-400">
            <h3 class="text-xl   font-semibold text-gray-900 dark:text-white">Services d'accompagnement aux démarches de voyage</h3>
            <p class="my-4">Vous souhaitez voyager à l'international ? Que ce soit pour des études, du travail ou des loisirs, les démarches administratives peuvent être complexes et décourageantes. LAWEL DIA SERVICES vous accompagne dans tout le processus pour simplifier vos démarches de visa et de migration vers des destinations telles que l'Europe, la Chine, les États-Unis et le Canada.

</p>
        </blockquote>
        
<h2 class="mb-2 text-xl   font-semibold text-gray-900 dark:text-white">Ce que nous offrons :</h2>
<ul class="max-w-md space-y-1 text-gray-500 list-inside dark:text-gray-400">
    <li class="flex items-center">
        <svg class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
         </svg>
         Assistance pour les demandes de visa
    </li>
    <li class="flex items-center">
        <svg class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
         </svg>
         Conseils en immigration
    </li>
    <li class="flex items-center">
        <svg class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
         </svg>
         Préparation des documents de voyage
         </li>
    <li class="flex items-center">
        <svg class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
         </svg>
         Suivi des dossiers jusqu'à obtention des autorisations.
         </li>
</ul>
    
    </figure>

    <figure class="flex flex-col items-center justify-center p-8 text-center bg-white border-b border-gray-200 rounded-t-lg md:rounded-t-none md:rounded-ss-lg md:border-e dark:bg-gray-800 dark:border-gray-700">
        <blockquote class="max-w-2xl mx-auto mb-4 text-gray-500 lg:mb-8 dark:text-gray-400">
            <h3 class="text-xl   font-semibold text-gray-900 dark:text-white">Services d'accompagnement aux démarches de voyage</h3>
            <p class="my-4">Vous souhaitez voyager à l'international ? Que ce soit pour des études, du travail ou des loisirs, les démarches administratives peuvent être complexes et décourageantes. LAWEL DIA SERVICES vous accompagne dans tout le processus pour simplifier vos démarches de visa et de migration vers des destinations telles que l'Europe, la Chine, les États-Unis et le Canada.

</p>
        </blockquote>
        
<h2 class="mb-2 text-xl   font-semibold text-gray-900 dark:text-white">Ce que nous offrons :</h2>
<ul class="max-w-md space-y-1 text-gray-500 list-inside dark:text-gray-400">
    <li class="flex items-center">
        <svg class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
         </svg>
         Assistance pour les demandes de visa
    </li>
    <li class="flex items-center">
        <svg class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
         </svg>
         Conseils en immigration
    </li>
    <li class="flex items-center">
        <svg class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
         </svg>
         Préparation des documents de voyage
         </li>
    <li class="flex items-center">
        <svg class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
         </svg>
         Suivi des dossiers jusqu'à obtention des autorisations.
         </li>
</ul>
    
    </figure>



</div>
</div> */}
<div class="relative overflow-hidden bg-primary pt-16 pb-32 space-y-24">
    <div class="relative">
        <div class="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8 ">
            <div class="mx-auto max-w-xl px-6 lg:mx-0 lg:max-w-none lg:py-16 lg:px-0 ">

                <div>
                    

                    <div class="mt-6">
                        <h2 class="text-3xl font-bold tracking-tight text-white">
                        Services d'accompagnement aux démarches de voyage:
                        </h2>
                        <p class="mt-4 text-lg text-gray-300">
                        Voyager à l'étranger, que ce soit pour des études, des opportunités professionnelles ou des vacances, peut rapidement devenir une tâche complexe en raison des nombreuses démarches administratives à accomplir. LAWEL DIA SERVICES vous accompagne à chaque étape du processus, en simplifiant les démarches de visa et de migration pour des destinations telles que l’Europe, la Chine, les États-Unis et le Canada. Nous vous aidons à préparer vos documents, à comprendre les exigences des différents pays et à assurer le suivi des dossiers pour une obtention rapide des autorisations nécessaires.

                        </p>
                       
                    </div>
                </div>
            </div>
            <div class="mt-12 sm:mt-16 lg:mt-0">
                <div class="-mr-48 pl-6 md:-mr-16 lg:relative lg:m-0 lg:h-full lg:px-0">
                    <img loading="lazy" width="647" height="486"
                        class="w-full rounded-xl shadow-2xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                         src="/img/service1.jpg" />
                </div>
            </div>
        </div>
    </div>



    <div class="relative">
        <div class="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8 ">
            <div class="mx-auto max-w-xl px-6 lg:mx-0 lg:max-w-none lg:py-16 lg:px-0 lg:col-start-2">
                <div>
                   
                    <div class="mt-6">
                        <h2 class="text-3xl font-bold tracking-tight text-white">
                        Services immobiliers :
                        </h2>
                        <p class="mt-4 text-lg text-gray-300">
                        Dans le domaine immobilier, LAWEL DIA SERVICES s'impose comme un partenaire de choix. Nous accompagnons nos clients dans l'achat, la vente et la location de biens immobiliers, qu'il s'agisse de maisons, d’appartements ou de bureaux. Notre expertise en gestion locative et en conseil en investissement permet à nos clients de sécuriser et de rentabiliser leurs placements immobiliers tout en bénéficiant d’un accompagnement de qualité. Nous travaillons pour vous offrir des solutions immobilières adaptées à vos besoins et à votre budget, que vous soyez à la recherche d’un bien personnel ou professionnel.
                        </p>
                        {/* <div class="mt-6">
                            <a class="inline-flex rounded-lg bg-secondary px-4 py-1.5 text-base font-semibold leading-7 text-white shadow-sm ring-1 ring-secondary hover:bg-pink-700 hover:ring-pink-700"
                                href="/contact">
                                Contacter nous
                            </a>
                        </div> */}
                    </div>
                </div>
            </div>
            <div class="mt-12 sm:mt-16 lg:mt-0">
                <div class="-ml-48 pr-6 md:-ml-16 lg:relative lg:m-0 lg:h-full lg:px-0">
                    <img alt="Inbox user interface" loading="lazy" width="647" height="486"
                        class="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                         src="/img/service2.jpg" />
                </div>
            </div>
        </div>
    </div>



    <div class="relative">
        <div class="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8 ">
            <div class="mx-auto max-w-xl px-6 lg:mx-0 lg:max-w-none lg:py-16 lg:px-0 ">
                <div>
                    
                    <div class="mt-6">
                        <h2 class="text-3xl font-bold tracking-tight text-white">
                        Assurances :
                        </h2>
                        <p class="mt-4 text-lg text-gray-300">
                        La protection de vos biens et de votre famille est une priorité pour LAWEL DIA SERVICES. Nous proposons une gamme complète d’assurances qui couvre divers domaines essentiels. Nos assurances habitation, auto, santé et vie vous permettent de bénéficier d’une protection adaptée à vos besoins spécifiques, en vous assurant une tranquillité d’esprit totale. Que vous cherchiez à assurer votre logement, votre véhicule ou à souscrire une couverture santé, nous nous engageons à vous fournir des solutions d’assurance flexibles et accessibles.

                        </p>
                        {/* <div class="mt-6">
                            <a class="inline-flex rounded-lg bg-secondary px-4 py-1.5 text-base font-semibold leading-7 text-white shadow-sm ring-1 ring-secondary hover:bg-pink-700 hover:ring-pink-700"
                                href="/contact">
                                Contacter nous
                            </a>
                        </div> */}
                    </div>
                </div>
            </div>
            <div class="mt-12 sm:mt-16 lg:mt-0">
                <div class="-mr-48 pl-6 md:-mr-16 lg:relative lg:m-0 lg:h-full lg:px-0">
                    <img loading="lazy" width="646" height="485"
                        class="w-full rounded-xl shadow-2xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                        
                        src="/img/service3.jpg" />
                </div>
            </div>
        </div>
    </div>

    <div class="relative">
        <div class="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8 ">
            <div class="mx-auto max-w-xl px-6 lg:mx-0 lg:max-w-none lg:py-16 lg:px-0 lg:col-start-2">
                <div>
                   
                    <div class="mt-6">
                        <h2 class="text-3xl font-bold tracking-tight text-white">
                        Autres services :
                        </h2>
                        <p class="mt-4 text-lg text-gray-300">
                        En tant qu'entreprise polyvalente, LAWEL DIA SERVICES étend son expertise à plusieurs autres domaines pour offrir un service complet à ses clients. Nous fournissons des services de conseil en entreprise, d’accompagnement à la création de sociétés, ainsi que de nombreuses autres démarches administratives. Chaque service est conçu pour faciliter la vie de nos clients, tout en leur offrant des solutions pratiques et adaptées à leurs projets, qu’ils soient personnels ou professionnels.

</p>
                        {/* <div class="mt-6">
                            <a class="inline-flex rounded-lg bg-secondary px-4 py-1.5 text-base font-semibold leading-7 text-white shadow-sm ring-1 ring-secondary hover:bg-pink-700 hover:ring-pink-700"
                                href="/contact">
                                Contacter nous
                            </a>
                        </div> */}
                    </div>
                </div>
            </div>
            <div class="mt-12 sm:mt-16 lg:mt-0">
                <div class="-ml-48 pr-6 md:-ml-16 lg:relative lg:m-0 lg:h-full lg:px-0">
                    <img alt="Inbox user interface" loading="lazy" width="647" height="486"
                        class="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                         src="/img/service4.jpg" />
                </div>
            </div>
        </div>
    </div>



  
</div>

{/* <section class="w-full mx-auto  bg-gray-50 dark:bg-gray-900 dark:text-white">

    <div class="xl:w-[80%] sm:w-[85%] items-center xs:w-[90%] mx-auto flex md:flex-row xs:flex-col lg:gap-4 xs:gap-2 justify-center lg:items-stretch md:items-center ">
      <div class="lg:w-[50%] xs:w-full">
        <img class=" sm:rounded-sm xs:rounded-sm" src="https://images.unsplash.com/photo-1512917774080-9991f1c4c750?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0NzEyNjZ8MHwxfHNlYXJjaHw1fHxob21lfGVufDB8MHx8fDE3MTA0OTAwNjl8MA&ixlib=rb-4.0.3&q=80&w=1080" alt="billboard image" />
      </div>
      <div class="lg:w-[50%]  sm:w-full xs:w-full bg-gray-100 dark:bg-gray-900 dark:text-gray-400 md:p-4 xs:p-0 rounded-md">
        <h2 class="text-3xl font-semibold text-gray-900 dark:text-white">Pourquoi choisir LAWEL DIA SERVICES ?</h2>
        <p class="text-md mt-4">
        Depuis notre création en 2014, nous avons bâti une solide réputation en fournissant des services de qualité et en mettant nos clients au centre de nos préoccupations. Notre équipe d’experts est là pour vous guider à chaque étape, que ce soit pour des projets de voyage, des investissements immobiliers, ou des démarches administratives. Avec une présence solide à Dakar et des agences dans les quartiers de VDN, Ouakam et Pikine, LAWEL DIA SERVICES continue d’étendre ses services dans des villes comme Thiès, Saint-Louis, Ziguinchor et Mbour, pour mieux servir notre clientèle à travers tout le Sénégal.
<br /><br />
En choisissant LAWEL DIA SERVICES, vous faites le choix de l’expertise, de la qualité et de la proximité. Nous sommes fiers de vous accompagner dans vos projets, en vous apportant les solutions les plus adaptées à vos besoins. Contactez-nous dès aujourd'hui pour en savoir plus sur nos services et comment nous pouvons vous aider à concrétiser vos ambitions.


          </p>
      </div>
    </div>
    
    <div class="xl:w-[80%] sm:w-[85%] xs:w-[90%] mx-auto flex md:flex-row xs:flex-col lg:gap-4 xs:gap-2 justify-center lg:items-stretch md:items-center mt-6">
      
      <div class="md:hidden sm:block xs:block xs:w-full">
        <img class="lg:rounded-t-lg sm:rounded-sm xs:rounded-sm" src="https://images.unsplash.com/photo-1516455590571-18256e5bb9ff?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0NzEyNjZ8MHwxfHNlYXJjaHwxMXx8aG9tZXxlbnwwfDB8fHwxNzEwNDkwMDcwfDA&ixlib=rb-4.0.3&q=80&w=1080" alt="billboard image" />
      </div>
      
      <div class="lg:w-[50%] xs:w-full bg-gray-100 dark:bg-gray-900 dark:text-gray-400 md:p-4 xs:p-0 rounded-md">
        <h2 class="text-3xl font-semibold text-gray-900 dark:text-white">Nos Services</h2>

        <p class="text-md mt-4">Chez LAWEL DIA SERVICES (LDS), nous offrons une large gamme de services pour répondre aux besoins diversifiés de nos clients, qu'il s'agisse de faciliter les démarches administratives ou d'accompagner dans des projets spécifiques. Notre mission est de simplifier votre quotidien en vous proposant des solutions fiables et adaptées, tout en garantissant un accompagnement personnalisé.

</p>
      </div>
      
      <div class="md:block sm:hidden xs:hidden lg:w-[50%] xs:w-full">
        <img class="lg:rounded-t-lg xs:rounded-sm" src="https://images.unsplash.com/photo-1516455590571-18256e5bb9ff?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0NzEyNjZ8MHwxfHNlYXJjaHwxMXx8aG9tZXxlbnwwfDB8fHwxNzEwNDkwMDcwfDA&ixlib=rb-4.0.3&q=80&w=1080" alt="billboard image" />
      </div>
    </div>
  </section> */}
  <div class="relative my-12 flex w-full flex-col items-center sm:mt-24">
  <a target="_blank" rel="noreferrer" href="https://example.com"
    class="mx-auto flex max-w-fit items-center justify-center space-x-2 overflow-hidden rounded-full bg-blue-100 px-7 py-2 transition-all hover:bg-blue-200">
    
    <p class="text-sm font-semibold text-[#1d9bf0]">LDS </p>
  </a>
  <h1
    class="mt-8 max-w-sm bg-gradient-to-br from-primary via-secondary to-primary bg-clip-text text-center text-4xl font-extrabold text-transparent sm:max-w-4xl sm:text-6xl">
    LAWEL DIA SERVICE SUARL
    </h1>
  <span class="mt-6 max-w-xl text-center text-lg leading-relaxed text-gray-800">
  Chez LAWEL DIA SERVICES (LDS), nous offrons une large gamme de services pour répondre aux besoins diversifiés de nos clients, qu'il s'agisse de faciliter les démarches administratives ou d'accompagner dans des projets spécifiques. Notre mission est de simplifier votre quotidien en vous proposant des solutions fiables et adaptées, tout en garantissant un accompagnement personnalisé.

  </span>
  
</div>

<Cta title='Service' img='about-hero.png' />
<Footer />
            </div>
     );
}

export default Service;