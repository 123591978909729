import React from 'react'
import Title1 from '../components/title1';

function Dg() {
    return ( 
        <div className="bg-secondary p-4 ">
            <div className="container mx-auto">
                    <Title1 text='Mot du directeur' classes='text-white px-5' />
            <div className="grid grid-cols-1 lg:grid-cols-3 items-center">
                <div className="col-span-2">
                    <p className='text-white text-justify' >
                    Chers partenaires et clients, <br /> <br />

Je suis honoré de m’adresser à vous en tant que Directeur Général de LAWEL DIA SERVICES (LDS). Depuis notre création en 2014, notre objectif a été clair : offrir des solutions efficaces et personnalisées pour simplifier les démarches administratives et répondre aux besoins variés de nos clients. Chaque jour, nous nous efforçons de maintenir un haut niveau de qualité dans nos services, qu’il s’agisse de faciliter vos voyages, de vous accompagner dans vos projets immobiliers, ou de vous fournir les assurances dont vous avez besoin.
<br /> <br />
Je tiens à exprimer ma profonde gratitude à notre équipe dévouée et à nos précieux partenaires, dont le soutien et l’engagement ont été essentiels à notre succès. Grâce à vous, nous avons pu élargir notre offre, étendre notre présence et continuer à innover pour mieux servir notre communauté. Nous restons déterminés à poursuivre notre mission avec passion et à contribuer positivement à la société à travers nos projets communautaires et nos initiatives sociales.
<br />
Merci de faire partie de l’histoire de LAWEL DIA SERVICES. Nous sommes impatients de continuer à travailler ensemble et à réaliser de nouveaux succès.
<br /> <br />
Cordialement,
br
Seikou DIA <br />
Directeur Général, LAWEL DIA SERVICES (LDS)
                    </p>
                </div>
                <div>
                    <img src="/img/dg.png" alt="image dg" />
                </div>
            </div>
            </div>
            </div>
     );
}

export default Dg;