// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();


import * as React from "react";
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";
import App from "./App";
import About from "./pages/about/about";
import './index.css';
import Service from "./pages/service/service";
import Contact from "./pages/contact/contact";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
         <App />

    ),
  },
  {
    path: "a-propos",
    element:<About />,
  },
  {
    path: "services",
    element:<Service />,
  },
  {
    path: "contact",
    element:<Contact />,
  },
]);

createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />
);