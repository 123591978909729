import React from 'react'
import Topbar from '../../components/topbar';
import HeroSm from '../../components/heroSm';
import Title1 from '../../components/title1';
import Chiffres from '../../sections/chiffres';
import Footer from '../../sections/footer';
function Contact() {
    React.useEffect(() => {
        document.title = 'LAWEL DIA SERVICES - Contactez nous';
      }, []);
    return ( <div>
        <Topbar />
        <HeroSm title='Contact' img='contact-hero.jpg' />

        <div className="container mx-auto mt-5">
            <div className="flex flex-wrap justify-center xl:justify-between">
                <div className="shadow my-2 bg-white md:mx-2 w-[220px] text-center py-4 px-2">
                    <img src="/img/locate.svg" alt="icon contact" className='mx-auto' />
                    <p className="mt-4">Sacré Coeur 3 VDN en face ETICCA, côté cimetière Saint-Lazard, Dakar, Sénégal.</p>
                </div>
                <div className="shadow my-2 bg-white md:mx-2 w-[220px] text-center py-4 px-2">
                    <img src="/img/call.svg" alt="icon contact" className='mx-auto' />
                    <p className="mt-4">(+221) 77 806 79 60 <br />(+221) 78 134 52 52</p>
                </div>
                <div className="shadow my-2 bg-white md:mx-2 w-[220px] text-center py-4 px-2">
                    <img src="/img/mail.svg" alt="icon contact" className='mx-auto' />
                    <p className="mt-4">info@laweldiaservices.com</p>
                </div>
                <div className="shadow my-2 bg-white md:mx-2 w-[220px] text-center py-4 px-2">
                    <img src="/img/web.svg" alt="icon contact" className='mx-auto' />
                    <p className="mt-4">laweldiaservices.com</p>
                </div>
            </div>

            <div className="form bg-secondary p-2 md:p-4 lg:mx-6 my-6" style={{marginTop : "-4rem" , paddingTop :  "5rem"}}>

                <Title1 text='Contacter nous' classes='text-white text-center' />
                <div className='text-center text-white'>
                    discutons directement sur Whatsapp
                </div>
                <form className='mt-4' action="">
                    <div className="grid grid-cols-1 lg:grid-cols-2">
                        <div class="py-6 mx-4">
                            <input class="shadow bg-transparent border-white rounded w-full py-2 px-3 text-white   focus:outline-transparent focus:shadow-transparent" id="username" type="text" placeholder="Prénom Nom" />
                        </div>
                        <div class="py-6 mx-4">
                            <input class="shadow bg-transparent border-white rounded w-full py-2 px-3 text-white   focus:outline-transparent focus:shadow-transparent" id="username" type="text" placeholder="Email" />
                        </div>

                        <div class="py-6 mx-4 lg:col-span-2">
                            <textarea class="shadow bg-transparent border-white rounded w-full py-2 px-3 text-white   focus:outline-transparent focus:shadow-transparent" id="username" type="text" placeholder="Méssage " />
                        </div>
                    </div>
                <div className="text-center">
                    <button class="bg-primary hover:bg-primary-700 text-white font-bold py-2 px-6 rounded">
                        Envoyer
                    </button>
                    </div>
                
                </form>
            </div>
        </div>
        {/* <Chiffres /> */}
        <Footer />
        </div> );
}

export default Contact;